// 供应商信息管理
<template>
    <div class="baseBg">
        <div class="searchBar ofh breakSearch">
            <el-form :model="searchForm"
                     ref="searchForm"
                     class="fll"
                     :inline="true"
                     size="normal">
                <el-form-item label="合同名称">
                    <el-input v-model="searchForm.name"
                              placeholder="请输入合同名称"
                              clearable />
                </el-form-item>

                <el-form-item label="项目客户">
                    <selectTree placeholderStr="请选择客户/供应商"
                                :showSeeProvider="false"
                                :shouldClean="true"
                                @handClear="clearProvider"
                                :cleanProvider="cleanProvider"
                                @treeSelectNodeClick="customerClick"></selectTree>
                </el-form-item>

                <el-form-item label="合同类型">
                    <el-select v-model="searchForm.type"
                               placeholder="请选择合同类型">
                        <el-option v-for="item in options"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <template v-if="showMoreSearch">
                    <el-form-item label="状态">
                        <el-select v-model="searchForm.status"
                                   placeholder="请选择合同类型">
                            <el-option v-for="item in stateOpt"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="部门">
                        <deptSelectTree placeholderStr="请选择部门"
                                        :needClean="needClean"
                                        :shouldClean="true"
                                        @handClear="clearDept"
                                        @treeSelectNodeClick="departmentClick"></deptSelectTree>
                    </el-form-item>

                    <el-form-item label="合同结束日期">
                        <el-date-picker v-model="searchForm.date"
                                        type="daterange"
                                        value-format="yyyy-MM-dd"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                </template>

                <el-form-item>
                    <el-button type="primary"
                               @click="search">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type=""
                               @click="celanSearch">清除</el-button>
                </el-form-item>

                <!-- 搜索栏是否显示更多 -->
                <div class="showMoreSearchBox">
                    <span :class="showMoreSearch ? 'close' : 'open'"
                          @click="showMoreSearch = !showMoreSearch">{{showMoreSearch ? '收起' : '展开'}}搜索</span>
                </div>

            </el-form>
        </div>

        <div class="listBox">
            <div class="tableBox">
                <el-table :data="tableData"
                          border
                          v-loading="isloading"
                          :height="tableHeight"
                          stripe>
                    <el-table-column prop="code"
                                     width="200"
                                     label="合同编号">
                    </el-table-column>
                    <el-table-column prop="name"
                                     width="200"
                                     label="合同名称">
                    </el-table-column>
                    <el-table-column prop="companyName"
                                     width="200"
                                     label="客户/供应商">
                    </el-table-column>
                    <el-table-column prop="type.text"
                                     width="80"
                                     align="center"
                                     label="合同类型">
                    </el-table-column>
                    <el-table-column prop="totalMoney"
                                     width="80"
                                     align="center"
                                     label="签订金额（元）">
                    </el-table-column>
                    <el-table-column prop="payTotalMoney"
                                     width="100"
                                     align="center"
                                     label="收付款金额（元）">
                    </el-table-column>
                    <el-table-column prop="contractProjectName"
                                     width="200"
                                     label="关联项目">
                    </el-table-column>
                    <el-table-column prop="deptName"
                                     width="140"
                                     label="部门">
                    </el-table-column>
                    <el-table-column prop="chargeUserName"
                                     width="80"
                                     align="center"
                                     label="负责人">
                    </el-table-column>

                    <el-table-column prop="startTime"
                                     width="110"
                                     label="合同开始日期">
                    </el-table-column>
                    <el-table-column prop="endTime"
                                     width="110"
                                     label="合同结束日期">
                    </el-table-column>

                    <el-table-column prop="finishTime"
                                     width="110"
                                     label="完成日期">
                    </el-table-column>
                    <el-table-column prop="finishStatus"
                                     width="60"
                                     align="center"
                                     label="状态">
                        <template slot-scope="scope">
                            {{scope.row.finishStatus === 'FINISH' ? '正常' : '异常'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="memo"
                                     width="200"
                                     label="备注">
                    </el-table-column>
                    <el-table-column label="操作"
                                     fixed="right"
                                     width="100">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     @click="details(scope.row,{isSeeDetail:true})">查看详情</el-link>
                        </template>
                    </el-table-column>

                </el-table>
                <!-- 分页 -->
                <Pagination :page="searchForm.page"
                            :limit="searchForm.size"
                            :total="pageTotal"
                            @pagination="paginationChange" />
            </div>
        </div>

    </div>
</template>

<script>
import contractManageApi from "@/api/contractManageApi.js";
import Pagination from "@/components/Pagination"; // 分页
import deptSelectTree from "@/components/selectTree/deptSelectTree"; //部门下拉树
// import customerOrProviderSelectTree from "@/components/selectTree/customerOrProviderSelectTree"; //客户/供应商下拉树
import selectTree from "@/components/selectTree/custSupChoose"; //客户/供应商选择
export default {
    name: "contractRecord",
    props: [],

    components: {
        Pagination,
        selectTree,
        deptSelectTree,
    },

    data() {
        return {
            showMoreSearch: false, // 是否显示更多搜索条件
            dialogVisible: false,
            searchForm: {
                name: "", //合同名称
                companyId: "", //客户或供应商id
                type: "", //合同类型
                deptId: "", //部门id
                status: "",
                date: ["", ""], //合同结束日期
                page: 1,
                size: 20,
            },
            options: [
                {
                    value: "",
                    label: "全部",
                },
                {
                    value: "PAY_CONTRACT",
                    label: "支出",
                },
                {
                    value: "INCOME_CONTRACT",
                    label: "收入",
                },
            ],
            stateOpt: [
                {
                    value: "",
                    label: "全部",
                },
                {
                    value: "FINISH",
                    label: "正常",
                },
                {
                    value: "EXCEPTION",
                    label: "异常",
                },
            ],
            //部门选择默认数据
            needClean: false, //清空部门
            cleanProvider: false, //清空客户供应商
            treeData: [],
            pageTotal: 0,
            isloading: false,
            rowData: null,
            tableData: [],
        };
    },

    created() {},

    mounted() {
        this.search();
    },

    methods: {
        /* 获取列表 */
        getList(searchForm = this.searchForm) {
            let date = this.searchForm.date || ["", ""];
            this.isloading = true;
            contractManageApi
                .getContractTable(
                    Object.assign({}, searchForm, {
                        status: this.searchForm.status
                            ? [this.searchForm.status]
                            : ["FINISH", "EXCEPTION"],
                        minFinishTime: date[0] || "",
                        maxFinishTime: date[1] || "",
                    })
                )
                .then((res) => {
                    this.isloading = false;
                    this.tableData = res.content.records;
                    this.pageTotal = res.content.total;
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        //查看详情
        details(e, edit) {
            this.$router.push({
                path: "/contractManage/contractManageDetail",
                query: {
                    data: JSON.stringify(
                        Object.assign(
                            {},
                            e,
                            { listType: "done", type: e.type.name },
                            edit || {}
                        )
                    ),
                },
            });
        },
        // 搜索
        search() {
            this.defaultSearch();
        },

        //清空筛选条件
        celanSearch() {
            this.searchForm.name = "";
            this.searchForm.companyId = "";
            this.searchForm.type = "";
            this.searchForm.deptId = "";
            this.searchForm.status = "";
            this.searchForm.date = null;
            this.needClean = Math.random();
            this.cleanProvider = Math.random();
            this.getList();
        },

        // 分页改变
        paginationChange(e) {
            this.getList(Object.assign(this.searchForm, e));
        },

        // 新增或編輯系統彈框 关闭
        closeSystemDialog() {
            this.dialogVisible = false;
            this.defaultSearch();
        },

        // 默认查询第一页
        defaultSearch() {
            this.searchForm.page = 1;
            this.getList();
        },

        // 表格的編輯操作
        editTable(e) {
            this.rowData = e;
            this.dialogVisible = true;
        },

        //客户选择
        customerClick(node) {
            this.searchForm.companyId = node.id;
        },

        //客户清空
        clearProvider() {
            this.searchForm.companyId = "";
        },

        //部门的选择
        departmentClick(node) {
            this.searchForm.deptId = node.id;
        },

        //部门清空
        clearDept() {
            this.searchForm.deptId = "";
        },
    },

    /* 组件缓存触发 */
    activated() {
        this.getList();
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 270 });
        },
    },

    watch: {},
};
</script>

<style lang='scss' scoped>
</style>
